import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import { pipe } from 'utils/helpersShared.utils'
import { stringReplaceAll } from 'utils/string.utils'
import { LoadingSpinner } from '@elo-kit/components/loading-spinner/LoadingSpinner'
import { EloTooltipMore } from '@elo-kit/components/elo-ui/elo-tooltip-more/EloTooltipMore'
import { useShopStores } from 'shop/hooks/use-store'

import { SummaryPricesDetails } from './SummaryPricesDetails'

interface SummaryRate {
  rateSum: number
  feesSum: number
  discountSum: number
  relatedVat?: number
  vatSum?: number
}

interface Rate {
  orderRatePrices: {
    data: {
      cfgs: {
        privateVatRate: number
        mustPayVat: boolean
        withVatNo: boolean
        vatBaseCountry: string
      }
      rate: {
        count: number
        vat: number
      }
      fees: {
        count: number
        vat: number
      }
      discount: {
        count: number
        vat: number
      }
    }
  }[]
}

interface Props {
  isTemplatePreview?: boolean
  isLoading?: boolean
  pricingPlan
  rates
  isCouponApplied?: boolean
  additionalFee?: {
    title: string
  }
  countryCode?: string
  withoutStarNearPrice?: boolean
}

export const SummaryPrices: React.FC<Props> = observer(function SummaryPrices(props) {
  const {
    isTemplatePreview,
    isLoading,
    rates,
    pricingPlan,
    isCouponApplied,
    additionalFee,
    countryCode,
    withoutStarNearPrice = false,
  } = props
  const { currenciesStore, countriesStore } = useShopStores()

  const getVatText = (firstRate) => {
    const textBySellable = []

    firstRate &&
      firstRate.orderRatePrices.map((orp) => {
        const { cfgs } = orp.data
        const text = cfgs.isBusiness ? cfgs.businessVatText : cfgs.privateVatText

        if (text) textBySellable.push(text)
      })

    return textBySellable.join('<br/>')
  }

  const getVatTextByRate = (firstRate: Rate, currencyKey: string) => {
    let res = ''
    const vatByRate = {}

    firstRate &&
      firstRate.orderRatePrices?.map((orp) => {
        const { cfgs, rate, fees, discount } = orp.data
        if (cfgs.privateVatRate > 0 && !cfgs.mustPayVat && cfgs.withVatNo) {
          const vatSum =
            rate.count * rate.vat + (fees ? fees.vat * fees.count : 0) + (discount ? discount.vat * discount.count : 0)
          const key = `${cfgs.privateVatRate}_${cfgs.vatBaseCountry}`

          vatByRate[key] = vatByRate[key] || 0
          vatByRate[key] += vatSum
        }
      })

    for (const rate_cc in vatByRate) {
      const rate = rate_cc.split('_')[0]
      const countryCode = rate_cc.split('_')[1]
      const countryName = countriesStore.getCountry(countryCode).name
      const amount = currenciesStore.convertToPrice(vatByRate[rate_cc], currencyKey)

      res += I18n.t('react.shared.payment.reverse_charge', {
        rate: `${rate}%`,
        amount,
        country: countryName,
      })
      res += '<br/>'
    }
    return res
  }

  if (isLoading && !isTemplatePreview) {
    return <LoadingSpinner size='small' />
  }

  const firstRate = rates?.[0]
  const secondRate = rates?.[1]

  const currencyKey = currenciesStore.getKey(pricingPlan?.currencyId)
  const convertToPrice = (value) => currenciesStore.convertToPrice(value, currencyKey)

  let sumTotal: SummaryRate = {
    rateSum: 0,
    feesSum: 0,
    discountSum: 0,
    relatedVat: 0,
    vatSum: 0,
  }
  let secondSumTotal: SummaryRate = {
    rateSum: 0,
    feesSum: 0,
    discountSum: 0,
    vatSum: 0,
  }
  let isBusiness = false
  let includedVat = false

  firstRate &&
    firstRate.orderRatePrices?.map((orp) => {
      const { cfgs, rate, fees, discount } = orp.data
      const priceKey = cfgs.mustPayVat ? 'gross' : 'net'

      const rateSum = rate[priceKey] * rate.count
      const feesSum = fees ? fees[priceKey] * fees.count : 0
      const discountSum = discount ? discount[priceKey] * discount.count : 0
      const relatedVat = cfgs.isBusiness ? cfgs.businessVatRate : cfgs.privateVatRate

      const vatRate = rate ? rate.vat * rate.count : 0
      const vatFees = fees ? fees.vat * fees.count : 0
      const vatDiscount = discount ? discount.vat * discount.count : 0
      const vatSum = vatRate + vatFees + vatDiscount

      sumTotal = {
        rateSum: sumTotal.rateSum + rateSum,
        feesSum: sumTotal.feesSum + feesSum,
        discountSum: sumTotal.discountSum + discountSum,
        relatedVat,
        vatSum: sumTotal.vatSum + (cfgs.mustPayVat ? vatSum : 0),
      }

      isBusiness = isBusiness || cfgs.isBusiness
      includedVat = includedVat || relatedVat > 0
    })

  secondRate &&
    secondRate.orderRatePrices?.map((orp) => {
      const { rate, fees, discount } = orp.data

      const rateSum = rate.gross * rate.count
      const feesSum = fees ? fees.gross * fees.count : 0
      const discountSum = discount ? discount.gross * discount.count : 0

      secondSumTotal = {
        rateSum: secondSumTotal.rateSum + rateSum,
        feesSum: secondSumTotal.feesSum + feesSum,
        discountSum: secondSumTotal.discountSum + discountSum,
      }
    })

  const firstToPaySum = sumTotal.rateSum + sumTotal.feesSum + sumTotal.discountSum
  const nextToPaySum = secondSumTotal.rateSum + secondSumTotal.feesSum + secondSumTotal.discountSum
  const total = sumTotal.rateSum + sumTotal.feesSum + sumTotal.discountSum
  const testPerHide = parseInt(pricingPlan?.prefs?.testPeriod, 10) > 0 && pricingPlan?.prefs?.testPeriodHideAmounts
  const testPerDesc =
    parseInt(pricingPlan?.prefs?.testPeriod, 10) > 0 &&
    pricingPlan?.testPeriodDescription &&
    pipe(
      (str) => stringReplaceAll(str, '%{test_days}', pricingPlan?.prefs?.testPeriod),
      (str) => stringReplaceAll(str, '%{first_charging_date}', pricingPlan.firstIntervalDate || ''),
      (str) => stringReplaceAll(str, '%{next_charging_date}', pricingPlan.nextIntervalDate || ''),
      (str) => stringReplaceAll(str, '%{first_amount}', convertToPrice(firstToPaySum)),
      (str) => stringReplaceAll(str, '%{next_amount}', convertToPrice(nextToPaySum)),
      (str) => stringReplaceAll(str, '%{vat_incl_star}', includedVat ? '*' : '')
    )(pricingPlan.testPeriodDescription)

  if (testPerHide) {
    const couponClasses = classNames('total row', {
      'w-coupon': isCouponApplied,
    })

    return (
      <Fragment>
        {testPerDesc && (
          <div className='test-period-description' translate='no' dangerouslySetInnerHTML={{ __html: testPerDesc }} />
        )}
        <div className='prices-info'>
          <div className='prices-item'>
            <div className={couponClasses}>
              <div className='col-auto total-title'>{I18n.t('react.shared.payment.total')}</div>
              <div className='col-auto ml-auto val' translate='no'>
                {convertToPrice(0)}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {testPerDesc && (
        <div className='test-period-description' translate='no' dangerouslySetInnerHTML={{ __html: testPerDesc }} />
      )}
      <div className='prices-info'>
        <div className='prices-item'>
          <div className='subtotal row'>
            <div className='col-auto'>{I18n.t('react.shared.payment.amount')}</div>
            <div className='col-auto ml-auto val' translate='no'>
              {convertToPrice(sumTotal.rateSum)}
            </div>
          </div>

          {sumTotal.feesSum > 0 && (
            <Fragment>
              <div className='additional-fee row'>
                <div className='col-auto'>
                  {additionalFee.title}
                  <span className='country'>{countryCode}</span>
                </div>
                <div className='col-auto ml-auto val' translate='no'>
                  {convertToPrice(sumTotal.feesSum)}
                </div>
              </div>

              <div className='subtotal row'>
                <div className='col-auto'>{I18n.t('react.shared.payment.amount')}</div>
                <div className='col-auto ml-auto val' translate='no'>
                  {convertToPrice(sumTotal.rateSum + sumTotal.feesSum)}
                </div>
              </div>
            </Fragment>
          )}

          {sumTotal.discountSum !== 0 && (
            <div className='subtotal row'>
              <div className='col-auto'>{I18n.t('react.shared.payment.discount')}</div>
              <div className='col-auto ml-auto val discount-price' translate='no'>
                {convertToPrice(sumTotal.discountSum)}
              </div>
            </div>
          )}

          <div className={`total row ${isCouponApplied ? 'w-coupon' : ''}`}>
            <div className='col-auto total-title'>{I18n.t('react.shared.payment.total')}</div>
            <div className='col-auto ml-auto val' translate='no'>
              {convertToPrice(total)}
              {!withoutStarNearPrice && (total > 0 ? ' *' : '')}
            </div>
          </div>

          {total > 0 && (
            <Fragment>
              <div className='vat-preview'>
                <span>
                  {I18n.t('react.shared.payment.vat')}
                  {firstRate.orderRatePrices?.length < 2 && ` (${sumTotal.relatedVat}%)`}:
                </span>
                <span>{convertToPrice(sumTotal.vatSum)}</span>
              </div>
              <div className='vat-included'>
                {includedVat ? (
                  <span>{I18n.t('react.shared.payment.vat_included')}</span>
                ) : (
                  <span dangerouslySetInnerHTML={{ __html: getVatText(firstRate) }} />
                )}
                <span className='price-details'>
                  <EloTooltipMore
                    id='price-details'
                    customIcon={(props) => (
                      <span className='price-details__link' {...props}>
                        {I18n.t('react.shared.payment.price_details_link')}
                      </span>
                    )}
                  >
                    <SummaryPricesDetails pricingPlan={pricingPlan} rates={rates} />
                  </EloTooltipMore>
                </span>
                {isBusiness && (
                  <div
                    translate='no'
                    className='mt-1'
                    dangerouslySetInnerHTML={{ __html: getVatTextByRate(firstRate, currencyKey) }}
                  />
                )}
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  )
})
